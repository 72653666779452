import React, {useRef, useEffect} from 'react';
import Slider from 'react-slick';

const AndarBaharVideoCards = ({
                                  cards,
                                  player,
                                  setShowCardCount = false,
                                  showCardCount = false,
                                  hidePlayers = false
                              }) => {
    // Split the card string into an array


    if (cards.includes(",")) {
        cards = cards.split(",");
    }


    if(hidePlayers === true){

        cards = cards.slice(3, cards.length)

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const sliderRef = useRef(null);
    // Filter cards based on their index
    const baharCards = cards?.filter((_, index) => index % 2 === 0).filter(Boolean); // Odd indices (0, 2, 4...)
    const andarCards = cards?.filter((_, index) => index % 2 !== 0).filter(Boolean); // Even indices (1, 3, 5...)

    // Determine which cards to display based on the player
    const allcards = player === 'Andar' ? andarCards : baharCards;

    // Effect to scroll to the last slide when the component mounts
    useEffect(() => {
        if (sliderRef.current && allcards.length > 0) {
            sliderRef.current.slickGoTo(allcards.length - settings.slidesToShow);
        }
    }, [allcards]);
    // Slider settings
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        arrows: true, // Show arrows
        dots: false,  // Hide dots

    };
    if (!Array.isArray(cards)) {
        return null;
    }
    // Reference to the slider

    const allCardsAreOne = allcards.every(card => card === '1');

    // If all cards are '1', hide the slider
    if (allCardsAreOne) {
        if (typeof setShowCardCount === 'function') {
            setShowCardCount(false)
        }
        return null;
    } else {
        if (typeof setShowCardCount === 'function' && showCardCount === false) {
            setShowCardCount(true)
        }
    }

    return (
        <div className="card-inner" style={{width: "50px"}}>
            {hidePlayers === false && (
                <p className="text-white mb-1"><b>{player}</b></p>
            )}
            <Slider
                {...settings}
                ref={sliderRef}
            >
                {allcards.map((card, index) => {
                    if (card !== '1') {
                        return (
                            <div id={`${player.toLowerCase()}-cards`} key={index} className="slick-cards"
                            >
                                <img src={`${process.env.REACT_APP_CARD_PATH}${card}.png`} alt={card}/>
                            </div>
                        );
                    }
                    return null;
                })}
            </Slider>
        </div>
    );
};

export default AndarBaharVideoCards;
