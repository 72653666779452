import {useParams} from "react-router-dom";
import axiosFetch from "../../../Common_Functions/Constants";
import {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import DOMPurify from 'dompurify';
import {Teen32} from "./LastResults/Teen32";
import {Teen9} from "./LastResults/Teen9";
import {Teen8} from "./LastResults/Teen8";
import {Poker6} from "./LastResults/Poker6";
import {Baccarat} from "./LastResults/Baccarat";
import {Dt20} from "./LastResults/Dt20";
import {Card32} from "./LastResults/Card32";
import {Lucky7} from "./LastResults/Lucky7";
import {Superover} from "./LastResults/Superover";
import {Cricketv3} from "./LastResults/Cricketv3";
import {Teen120} from "./LastResults/Teen120";

export const CasinoLastResult = ({lastResults, sportList}) => {
    const {match_id} = useParams();

    const [lastResultData, setLastResultData] = useState([])

    const individualResult = useRef('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);


    const openPopup = async (key) => {
        setShow(true);
        if (!lastResultData[key]) {
            const fdata = { match_id: match_id, roundID: key };
            const response = await axiosFetch('last-result', 'post', null, fdata); // Adjust the URL according to your API
            individualResult.current = response.data; // Store the fetched data in the ref
            setLastResultData((prevData) => ({
                ...prevData,
                [key]: response.data // Update the state with the fetched data
            }));
        } else {
            // If we already have the data, just set it to the ref
            individualResult.current = lastResultData[key];
        }


    }

    const showCasinoResult = async () => {
        try {
            // Create an array to hold promises
            const requests = Object.entries(lastResults).map(([key, result]) => {
                const ldata = {match_id: match_id, roundID: result.mid}; // Assuming lastResults has a match_id property
                return axiosFetch('last-result', 'post', null, ldata); // Adjust the URL according to your API
            });

            // Await for all requests to resolve
            const responses = await Promise.all(requests);

            const fetchedData = {};

// Iterate over the responses and populate the fetchedData object
            responses.forEach(response => {
                const roundID = JSON.parse(response.config.data).roundID; // Extract roundID from the request data
                fetchedData[roundID] = response.data; // Assign the response data to the fetchedData object using roundID as the key
            });


            setLastResultData(fetchedData);


        } catch (error) {
            console.error('Error fetching casino result:', error);
        }
    };

    const midList = Object.entries(lastResults).map(([key, result]) => result.mid);

    // Store previous midList in a ref
    const prevMidListRef = useRef();

    // Only run effect if midList changes compared to the previous one
    useEffect(() => {
        const prevMidList = prevMidListRef.current;

        // Compare the current midList with the previous one
        if (prevMidList && JSON.stringify(midList) !== JSON.stringify(prevMidList)) {
            // Trigger the function if there's a change in midList
            showCasinoResult();
        }

        // Update the ref with the current midList
        prevMidListRef.current = midList;
    }, [midList])


    // Determine what to render based on match_id
    const renderResults = () => {
        switch (match_id) {
            case "teen32":
               return <Teen32 lastResults={lastResults} openPopup={openPopup} />
            case "teen9" :
                return <Teen9 lastResults={lastResults} openPopup={openPopup} />
            case "teen8" :
            case "ab20" :
            case "ab3" :
            case "3cardj":
            case "war":
            case "worli":
            case "worli2":
                return <Teen8 lastResults={lastResults} openPopup={openPopup} />
            case "poker6" :
                return <Poker6 lastResults={lastResults} openPopup={openPopup} />
            case "baccarat":
            case "baccarat2":
                return <Baccarat lastResults={lastResults} openPopup={openPopup} />
            case "dt20":
            case "dt202":
            case "dt6":
                return <Dt20 lastResults={lastResults} openPopup={openPopup} />
            case "card32":
            case "card32eu":
                return <Card32 lastResults={lastResults} openPopup={openPopup} />

            case "lucky7":
            case "lucky7eu":
            case "lucky7eu2":
                return <Lucky7 lastResults={lastResults} openPopup={openPopup} />

            case "superover":
                return <Superover lastResults={lastResults} openPopup={openPopup} />

            case "cricketv3":
                return <Cricketv3 lastResults={lastResults} openPopup={openPopup} />

            case "teen120":
                return <Teen120 lastResults={lastResults} openPopup={openPopup} />

            default:
                return <Teen32 lastResults={lastResults} openPopup={openPopup} />

        }
    };

    return (
        <>
            <div className="casino-last-results">
                {renderResults()}
            </div>
            <   Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>{sportList?.match_name } Result</Modal.Title>
                </Modal.Header>
                <Modal.Body id="casino-result-popup-body">
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(individualResult.current)}}/>

                </Modal.Body>

            </Modal>
        </>
    );
};
